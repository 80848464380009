


















































































































import { Component, Vue } from 'vue-property-decorator';
import NavItem from '@/components/navigation/NavItem.vue';
import { mapActions } from 'vuex';
import CustomerIcon from '@/assets/navbar/customer.svg?inline';
import LogoutIcon from '@/assets/navbar/logout.svg?inline';
import HowToIcon from '@/assets/navbar/howto.svg?inline';
import HowToVideoIcon from '@/assets/navbar/howtovideo.svg?inline';
import AboutUs from '@/assets/navbar/aboutus.svg?inline';
import Terms from '@/assets/navbar/terms.svg?inline';
import Support from '@/assets/navbar/support.svg?inline';

@Component({
    components: {
        NavItem,
        LogoutIcon,
        CustomerIcon,
        HowToIcon,
        HowToVideoIcon,
        AboutUs,
        Terms,
        Support,
    },
    methods: {
        ...mapActions({
            logout: 'authenticationStorage/logout',
        }),
    },
})
export default class NavBar extends Vue {
    isOpen = true;

    logout!: () => Promise<void>;

    toggle(): void {
        this.isOpen = !this.isOpen;
    }

    onLogout(): void {
        this.logout().then(() => {
            this.$router.push({ name: 'Login' });
        });
    }
}
