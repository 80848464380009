var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "group"
    }
  }, [_c('img', {
    attrs: {
      "id": "icon",
      "src": require("@/assets/icon/search.svg")
    }
  }), _c('input', {
    attrs: {
      "id": "field",
      "placeholder": this.$i18n.t('users.form.placeholder').toString(),
      "type": "text"
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "input": function input(event) {
        return _vm.onSearch(event.target.value);
      }
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }