var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "layout"
  }, [_vm.isAuthenticated ? _c('nav-bar', {
    attrs: {
      "user": _vm.getUser
    }
  }) : _vm._e(), _c('div', {
    staticClass: "w-100 d-flex flex-column min-width-0"
  }, [!this.$route.meta.noHeader ? _c('Header', {
    attrs: {
      "user": _vm.getUser
    }
  }) : _vm._e(), _c('router-view', {
    staticClass: "inner-layout",
    class: {
      'no-header': this.$route.meta.noHeader
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }