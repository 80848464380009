














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component
export default class NavItem extends Vue {
    @Prop() isOpen!: boolean;

    @Prop() title!: string;

    @Prop({ default: null }) to!: string | Location | null;

    @Emit()
    click(): void {
        // Click event
    }
}
