















import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/navigation/Header.vue';
import NavBar from '@/components/navigation/NavBar.vue';
import { mapGetters } from 'vuex';

@Component({
    components: {
        Header,
        NavBar,
    },
    computed: {
        ...mapGetters({
            getUser: 'authenticationStorage/getUser',
            isAuthenticated: 'authenticationStorage/isAuthenticated',
        }),
    },
})
export default class Layout extends Vue {}
