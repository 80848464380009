





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/model/User';
import SearchForm from '@/components/form/SearchForm.vue';

@Component({
    components: {
        SearchForm,
    },
    data() {
        return {
            isSearchBarShown: false,
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                this.$data.isSearchBarShown = this.$route.path === '/users';
            },
        },
    },
})
export default class Header extends Vue {
    @Prop() user!: User | null;
}
