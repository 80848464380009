













import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters({ searchValue: 'userStorage/getSearchString' }),
    },
    methods: {
        ...mapActions({
            search: 'userStorage/search',
        }),
    },
})
export default class SearchForm extends Vue {
    search!: (searchString: string) => void;

    onSearch(searchString: string): void {
        this.search(searchString);
    }
}
