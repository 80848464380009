var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-bar",
    class: {
      open: _vm.isOpen
    }
  }, [_c('b-row', {
    staticClass: "m-0",
    attrs: {
      "align-h": _vm.isOpen ? 'between' : 'center',
      "align-v": "center"
    }
  }, [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "logo",
    attrs: {
      "src": require("@/assets/logo/welcome_logo.png")
    }
  })]), _c('div', {
    staticClass: "clickable",
    on: {
      "click": _vm.toggle
    }
  }, [_c('font-awesome-icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "chevron-left-icon",
    attrs: {
      "icon": "chevron-left",
      "size": "lg",
      "color": "rgb(69, 90, 100)"
    }
  }), _c('font-awesome-icon', {
    attrs: {
      "icon": "bars",
      "size": "lg",
      "color": "rgb(69, 90, 100)"
    }
  })], 1)]), _c('b-nav', {
    staticClass: "nav-bar",
    attrs: {
      "pills": "",
      "vertical": ""
    }
  }, [_c('div', [_c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.users'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'Users'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('CustomerIcon', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.how-to'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'HowTo'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('HowToIcon', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.how-to-videos'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'HowToVideos'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('HowToVideoIcon', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.terms-and-conditions'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'TermsAndConditions'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('Terms', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.about-us'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'AboutUs'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('AboutUs', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.support-and-help'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'SupportAndHelp'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('Support', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.pages.most-important'),
      "is-open": _vm.isOpen,
      "to": {
        name: 'MostImportant'
      }
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('Terms', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "mt-auto"
  }, [_c('nav-item', {
    attrs: {
      "title": _vm.$t('navigation.logout'),
      "is-open": _vm.isOpen
    },
    on: {
      "click": _vm.onLogout
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('LogoutIcon', {
          staticClass: "icon"
        })];
      },
      proxy: true
    }])
  }), _vm.isOpen ? _c('hr', {
    staticClass: "divider"
  }) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }