var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item', {
    class: {
      closed: !_vm.isOpen
    },
    attrs: {
      "to": _vm.to,
      "exact": "",
      "exact-active-class": "active"
    },
    on: {
      "click": _vm.click
    }
  }, [_vm._t("icon"), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "navbar-item-label"
  }, [_vm._v(_vm._s(_vm.title))])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }