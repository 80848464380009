var render = function () {
  var _vm$user, _vm$user2, _vm$user3, _vm$user4, _vm$user5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-0",
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', [(_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.firstName ? _c('h1', {
    staticClass: "header-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation.hello', {
    name: (_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.firstName
  })) + " ")]) : _vm._e()]), _vm.isSearchBarShown ? _c('b-col', {
    staticClass: "search-bar"
  }, [_c('search-form')], 1) : _vm._e(), _vm.user ? _c('b-col', {
    staticClass: "user-box",
    attrs: {
      "align-self": "center"
    }
  }, [_c('p', {
    staticClass: "user-name"
  }, [_vm._v(" " + _vm._s("".concat((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.firstName, " ").concat((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.lastName)) + " ")]), _c('p', {
    staticClass: "user-email"
  }, [_vm._v(_vm._s((_vm$user5 = _vm.user) === null || _vm$user5 === void 0 ? void 0 : _vm$user5.email))])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }